<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
  .el-popper {
    z-index: 9999 !important;
  }
</style>
<style type="text/css" scoped>
  .el-popper {
    z-index: 9999;
  }
  /deep/.annouce-group .ck-editor__editable {
    min-height: 405px !important;
  }

  /deep/.el-input--small .el-input__inner {
    height: 30px;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import {
    bigTypeList,
    smallTypeList,
    newsDetail,
    saveNews
  } from "@/api/web/news.js";
  import {
    getUploadLimit,
    checkFile,
    uploadFiles
  } from "@/api/common/public.js";
  import {
    easyList,
    SecondEasyList
  } from "@/api/admin/base/mainType.js"

  /**
   * 新闻公告发布
   */
  export default {
    components: {
      Layout,
      PageHeader,



    },
    data() {
      return {
        title: "新闻公告发布 ",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "控制面板",
            active: true
          }
        ],
        editorData: "",
        bigTypeList: [],
        smallTypeList: [],
        newsId: "",
        urlInput: false,
        timerSelect: false,
        newsForm: {
          newsId: "",
          bigTypeId: "",
          bigType: "",
          smallTypeId: "",
          smallType: "",
          newsTitle: "",
          newsSort: "text",
          newsContent: "",
          newsUrl: "",
          imgUrl: "",
          newsFrom: "",
          contentUEdit: "",
          isTimer: "0",
          pubTime: "",
          flbm: "",
          isView: '0'
        },
        classList: [], // 考试大类
        secondList: [], // 考试二级分类
        // 文件上传
        uploadStatus: "",
        uploadModule: "Web",
        uploadLimit: {
          ext: "",
          size: 0
        },

      };
    },
    created() {
      this.setUEditorConfig() // 初始化百度编辑器
      this.UEditorConfig.initialFrameHeight = 400; //定义富文本高度
    },
    mounted() {
      getUploadLimit({
        module: this.uploadModule
      }).then(res => {
        if (res.status) {
          this.uploadLimit["ext"] = "," + res.data.extLimit.replaceAll(",", ",.");
          this.uploadLimit["size"] = res.data.sizeLimit;
        }
      });
      this.getBigTypeList();
      this.newsId = this.$route.params.newsId
      /** id不为空，需先查询详细数据 */
      if (this.newsId != undefined && this.newsId != '') {
        this.getNewsDetail(this.newsId);
      }

      this.getClassListPage()
    },
    methods: {
      /** 获取新闻详情 */
      getNewsDetail(newsId) {
        newsDetail(newsId).then(res => {
          if (res.status) {
            this.newsForm = res.data
            this.getSmallTypeList(res.data.bigTypeId)
            this.showUrlInput();
            this.showTimer()
            if (res.data.lbbm) {
              this.getSecondList(res.data.lbbm)
            }
          }
        });
      },
      /** 获取大类 */
      getBigTypeList() {
        bigTypeList().then(res => {
          if (res.status) {
            this.bigTypeList = res.data
          }
        });
      },
      /** 获取小类 */
      getSmallTypeList(bigTypeId, flag) {
        if (flag) {
          this.newsForm.smallTypeId = ""
        }
        this.smallTypeList = [];
        smallTypeList({
          bigTypeId: bigTypeId
        }).then(res => {
          if (res.status) {
            this.smallTypeList = res.data
          }
        });
      },

      // 考试类别
      getClassListPage() {
        easyList().then(res => {
          if (res.status) {
            this.classList = res.data
          }
        })
      },
      // 二级分类
      getSecondList(params, flag) {
        this.secondList = []
        if (flag) {
          this.newsForm.flbm = ""
        }
        SecondEasyList({
          lbbm: params
        }).then(res => {
          if (res.status) {
            this.secondList = res.data
          }
        })
      },

      getflmc() {
        let val = this.newsForm.flbm
        let obj = this.secondList.find(k => {
          return k.flbm === val
        })
        this.newsForm.flmc = obj.flmc

      },

      getlbmc() {
        let val = this.newsForm.lbbm
        let obj = this.classList.find(k => {
          return k.lbbm === val
        })
        this.newsForm.lbmc = obj.lbmc
        this.getSecondList(
          val,
          1
        );
      },

      uploadFile(file) {
        var _this = this;
        file = file.raw;
        if (checkFile(_this, this.uploadLimit.size, this.uploadLimit.ext, file)) {
          _this.uploadStatus = "loading";
          uploadFiles(_this.uploadModule, file).then(res => {
            if (res.status) {
              var file = res.data[0];
              if (file.status) {
                _this.newsForm.imgUrl = file.path;
                _this.$message({
                  type: "success",
                  message: "上传成功"
                });
              } else {
                _this.$message({
                  type: "error",
                  message: file.error
                });
              }
            }
            _this.uploadStatus = "";
          });
        }
      },
      /** 调整编辑模式 */
      showUrlInput() {
        if (this.newsForm.newsSort == 'url') {
          this.urlInput = true;
        } else {
          this.urlInput = false;
        }
      },
      /** 调整发布形式 */
      showTimer() {
        if (this.newsForm.isTimer == '1') {
          this.timerSelect = true;
        } else {
          this.timerSelect = false;
        }
      },
      /** 保存新闻 */
      submitForm() {
        // 校验数据
        if (this.checkForm()) {
          // 填充数据
          this.fillParam();
          // 提交
          let params = this.newsForm
          params.contentUEdit = this.newsForm.newsContent
          saveNews(params).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '添加成功!'
              });
              this.$router.push('/admin/announcement');
            }
          })
        }
      },
      /** 校验新闻参数 */
      checkForm() {
        if (this.newsForm.newsTitle == "") {
          this.checkAlert("标题不能为空")
          return false;
        }
        if (this.newsForm.bigTypeId == "") {
          this.checkAlert("新闻大类不能为空")
          return false;
        }
        if (this.newsForm.smallTypeId == "") {
          this.checkAlert("新闻小类不能为空")
          return false;
        }
        if (this.newsForm.newsContent == "" && !this.urlInput) {
          this.checkAlert("新闻内容不能为空")
          return false;
        }
        if (this.newsForm.newsUrl == "" && this.urlInput) {
          this.checkAlert("链接不能为空")
          return false;
        }
        if (this.newsForm.isTimer === "" || this.newsForm.isTimer == null || this.newsForm.isTimer == undefined) {
          this.checkAlert("请选择发布形式")
          return false;
        }
        if (this.newsForm.isTimer == "1" && (this.newsForm.pubTime == "" || this.newsForm.pubTime == undefined || this
            .newsForm.pubTime == null)) {
          this.checkAlert("请选择发布时间")
          return false;
        }
        return true;
      },
      /** 校验提醒 */
      checkAlert(message) {
        this.$message({
          type: 'error',
          message: message
        });
      },
      /** 提交前填充数据 */
      fillParam() {
        // 填充大类名称
        for (let item of this.bigTypeList) {
          if (item.bigTypeId == this.newsForm.bigTypeId) {
            this.newsForm.bigType = item.bigTypeName
          }
        }
        // 填充小类名称
        for (let item of this.smallTypeList) {
          if (item.smallTypeId == this.newsForm.smallTypeId) {
            this.newsForm.smallType = item.smallTypeName
          }
        }
        // 复制createTime到createTime2
        this.newsForm.createTime2 = this.newsForm.createTime
      },
      goToListPage() {
        this.$router.push({
          path: '/admin/announcement',
          name: 'announcement'
        })
      },
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <form class="w-100  announce-form">
              <div class="annouce-group flexList" style="height: 70px;">
                <div class="announce-name">新闻标题<span style="color: red;">*</span></div>
<!--                <input type="text" name="fileName" v-model="newsForm.newsTitle" class="form-control annouce-input">-->
                <textarea rows="2" cols="" class="form-control annouce-input pt-2 pb-2" style="font-size: 14px;" maxlength="200" name="fileName" v-model="newsForm.newsTitle"
                          placeholder="输入标题，可换行，样式自动居中"></textarea>
              </div>
              <div class="annouce-group flexList">
                <div class="announce-name ">新闻分类<span style="color: red;">*</span></div>

                <el-select class=" w-15 h30  mr-2 ml-2" clearable placeholder="新闻大类" v-model="newsForm.bigTypeId"
                  @change="getSmallTypeList(newsForm.bigTypeId,1)" size="small">
                  <el-option :value="item.bigTypeId" v-for="item in bigTypeList" :key="item.bigTypeId"
                    :label="item.bigTypeName">{{item.bigTypeName}}
                  </el-option>
                </el-select>

                <el-select class=" w-15 h30  mr-2 ml-2" clearable placeholder="新闻小类" v-model="newsForm.smallTypeId"
                  size="small">
                  <el-option :value="item.smallTypeId" v-for="item in smallTypeList" :key="item.smallTypeId"
                    :label="item.smallTypeName">{{item.smallTypeName}}
                  </el-option>
                </el-select>

                <span class="ml-2 text-danger">（* 请务必选择一个正确的新闻大类和小类）</span>
              </div>

              <div class="annouce-group flexList">
                <div class="announce-name ">考试分类</div>
                <el-select class=" w-15 h30 mr-2 ml-2" clearable v-model="newsForm.lbbm" @change="getlbmc" size="small">
                  <el-option :value="item.lbbm" v-for="item in classList" :key="item.lbbm" :label="item.lbmc">
                    {{item.lbmc}}
                  </el-option>
                </el-select>

                <el-select class=" w-15 h30 mr-2 ml-2" clearable v-model="newsForm.flbm" @change="getflmc" size="small">
                  <el-option :value="item.flbm" v-for="item in secondList" :key="item.flbm" :label="item.flmc">
                    {{item.flmc}}
                  </el-option>
                </el-select>
              </div>

              <div class="annouce-group flexList">
                <div class="announce-name">发布类型</div>
                <div class="frame-wrap w-75 ml-2">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="value1" value="text"
                      v-model="newsForm.newsSort" @change="showUrlInput()">
                    <label class="custom-control-label" style="line-height:19px" for="value1">表单提交 </label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input " id="value2" value="url"
                      v-model="newsForm.newsSort" @change="showUrlInput()">
                    <label class="custom-control-label" style="line-height:19px" for="value2">链接显示</label>
                  </div>
                  <div v-if="urlInput" class="custom-control custom-radio custom-control-inline w-35">
                    <input type="text" name="fileName" v-model="newsForm.newsUrl"
                      class="form-control annouce-input w-100" placeholder="请在此处输入新闻链接">
                  </div>
                </div>
              </div>

              <div class="annouce-group flexList">
                <div class="announce-name">是否发布</div>
                <div class="frame-wrap w-75 ml-2">
                  <div class="custom-control custom-radio custom-control-inline">

                      <input type="radio" id="isView1" class="custom-control-input" :value="1" v-model="newsForm.isView" >
                    <label class="custom-control-label" style="line-height:19px" for="isView1"> 是</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">

                      <input type="radio" id="isView0" class="custom-control-input " :value="0" v-model="newsForm.isView">
                    <label class="custom-control-label" style="line-height:19px" for="isView0">   否</label>
                  </div>
                </div>
              </div>
              <div class="annouce-group flexList" v-if="newsForm.isView == '1'">
                <div class="announce-name">发布形式</div>
                <div class="frame-wrap w-75 ml-2">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="value3" value="0" v-model="newsForm.isTimer"
                      @change="showTimer()">
                    <label class="custom-control-label" style="line-height:19px" for="value3">立即发布</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input " id="value4" value="1" v-model="newsForm.isTimer"
                      @change="showTimer()">
                    <label class="custom-control-label" style="line-height:19px" for="value4">定时发布</label>
                  </div>
                  <div v-if="timerSelect" class="custom-control custom-radio custom-control-inline w-35">
                    <el-date-picker v-model="newsForm.pubTime" type="datetime" size="small" style="width: 60%;"
                      class="mr-2 " placeholder="发布时间" @change="newsForm.pubTime=formatDateDetails(new Date($event))">
                    </el-date-picker>
                  </div>
                </div>
              </div>

              <div v-if="!urlInput" class="annouce-group flexList" style="height: 500px;">
                <div class="announce-name">新闻内容</div>
                <div class="ml-2 w-75 h-100 pt-2 pb-2">
                  <vue-ueditor-wrap v-model="newsForm.newsContent" :config="UEditorConfig"></vue-ueditor-wrap>

                </div>

              </div>
              <div class="annouce-group flexList">
                <div class="announce-name">添加时间</div>
                <el-date-picker v-model="newsForm.createTime" type="datetime" size="small"
                                class="ml-2 " placeholder="添加时间" @change="newsForm.createTime=formatDateDetails(new Date($event))">
                </el-date-picker>
              </div>
              <div class="annouce-group flexList">
                <div class="announce-name">发布来源</div>
                <input type="text" v-model="newsForm.newsFrom" class="form-control annouce-input">
              </div>
              <div class="annouce-group flexList" style="height: 100px;">
                <div class="announce-name">新闻配图</div>
                <div class=" annouce-right">
                  <div class="flexList"> <a v-if="newsForm.imgUrl" class="colorA mr-2"
                      :href="BASE_FILE_URL + newsForm.imgUrl" target="_blank">查看上传文件
                    </a>
                    <!--<el-form-item label="" prop="imgUrl" style="width:50%;">-->
                    <el-upload class="upload-demo" ref="upload" action="" :on-change="uploadFile"
                      :show-file-list="false" :accept="uploadLimit.ext" :auto-upload="false">
                      <el-button slot="trigger" size="small" type="primary">
                        <span name="default" v-if="uploadStatus != 'loading'">选取文件</span>
                        <span v-if="uploadStatus == 'loading'">上传中<i class="el-icon-loading"></i></span>
                      </el-button>
                      <el-tag type="danger" style="margin-left: 10px;">
                        支持上传{{ uploadLimit.ext }}文件，且不超过{{ uploadLimit.size/1024/1024 }}M</el-tag>
                    </el-upload>
                  </div>

                  <!--</el-form-item>-->
                  <div class="text-danger ml-2  mt-2">
                    提示：如果要发布的是新闻且需要显示在首页上，必须选择“新闻动态”大类并上传配图，文件大小不超过1m，建议图片宽高为245px*160px</div>
                </div>

              </div>
              <div class="m-2 text-center ">
                <button type="button" class="btn btn-info h30 w-sm mr-2" @click="submitForm">保存提交
                </button>
                <button type="button" class="btn btn-secondary h30 w-sm " @click="goToListPage">取消返回
                </button>
              </div>
            </form>


          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->


    <!-- 弹窗结束 -->
  </Layout>
</template>
